import { useAuth } from 'App/Modules/Auth'
import {
  useDeleteAuditNameMutation,
  useGetAllAuditNamesQuery,
  useUpdateAuditNameMutation,
} from 'App/Modules/Services/AuditName'
import ActionIcon from 'Template/assets/icons/ActionIcon'
import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'

interface ManageAuditModalProps {
  show: boolean
  handleClose: () => void
}

interface Audit {
  id: string
  name: string
  assets: string
  status: boolean
}

interface AuditNameData {
  data
  auditNames: Audit[]
}

const ManageAuditModal: React.FC<ManageAuditModalProps> = ({ show, handleClose }) => {
  const { currentUser } = useAuth()
  const [auditName, setAuditName] = useState('')
  const [auditId, setAuditId] = useState('')
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  const { data: auditNameData, refetch } = useGetAllAuditNamesQuery<AuditNameData>({
    userId: currentUser?.id,
  })

  const handleEditShow = (auditId: string, name: string) => {
    setAuditName(name)
    setAuditId(auditId)
    setCurrentModal('edit')
  }

  const handleEditClose = () => {
    setCurrentModal(null)
  }
  const handleDeleteShow = (auditId: string, name: string) => {
    setAuditName(name)
    setAuditId(auditId)
    setCurrentModal('delete')
  }
  const handleDeleteClose = () => {
    setCurrentModal(null)
  }
  const handleMainModalClose = () => {
    setCurrentModal(null)
    handleClose()
  }
  const [updateAuditName, { isLoading: isUpdating }] = useUpdateAuditNameMutation()
  const [deleteAuditName, { isLoading: isDeleting }] = useDeleteAuditNameMutation()

  const handleStatusChange = async (auditNameId: string, newStatus: boolean) => {
    try {
      const result = await updateAuditName({ auditNameId, values: { status: newStatus } })
      if (result.data.success) {
        toast.success(result?.data?.message)
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const handleEditAuditName = async (auditNameId: string, auditName: string) => {
    try {
      const result = await updateAuditName({ auditNameId, values: { name: auditName } })
      if (result.data.success) {
        handleEditClose()
        toast.success(result.data.message)
      }
    } catch (error) {
      toast.error('Failed to update audit name')
    }
  }

  const handleDeleteAuditName = async (auditNameId: string) => {
    try {
      const result = await deleteAuditName({ auditNameId })
      if (result.data.success) {
        handleDeleteClose()
        toast.success(result.data.message)
      }
    } catch (error) {
      toast.error('Failed to update audit name')
    }
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuditName(event.target.value)
  }

  useEffect(() => {
    if (!isUpdating || !isDeleting) {
      refetch()
    }
  }, [isUpdating, isDeleting, refetch])

  return (
    <>
      <Modal show={show && currentModal === null} onHide={handleMainModalClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Manage Audit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='table-responsive'>
            <table
              className='manage-audit-table text-center table table-sm table-bordered vertical_middle matPersons'
              id='matPersons'
              aria-label='Manage Audit'
            >
              <thead className='thead-light'>
                <tr>
                  <th>Audit Name</th>
                  <th className='text-center'>Assets</th>
                  <th>Status</th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {auditNameData?.auditNames?.map((audit: Audit, index: number) => (
                  <tr key={index}>
                    <td className='p-2'>{audit?.name}</td>
                    <td className='p-2'>{audit?.assets}</td>
                    <td className='p-2  mx-2 d-flex align-items-center justify-content-evenly radio-controls'>
                      <div className='d-flex justify-content-start '>
                        <input
                          type='radio'
                          id={`option1-${index}`}
                          name={`auditOption-${index}`}
                          className='custom-radio'
                          value='option1'
                          checked={audit?.status === false}
                          onChange={() => handleStatusChange(audit.id, false)}
                        />
                        <label className='custom-radio-label' htmlFor={`option1-${index}`}>
                          Lock
                        </label>
                      </div>
                      <div className='d-flex justify-content-start'>
                        <input
                          type='radio'
                          id={`option2-${index}`}
                          name={`auditOption-${index}`}
                          className='custom-radio'
                          checked={audit?.status === true}
                          onChange={() => handleStatusChange(audit.id, true)}
                        />
                        <label className='custom-radio-label ' htmlFor={`option2-${index}`}>
                          Unlock
                        </label>
                      </div>
                    </td>
                    <td role='cell' className='text-center h-auto'>
                      <div className='dropdown'>
                        <button
                          className='dropdown-toggle'
                          type='button'
                          id={`dropdownMenuButton-${index}`}
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          Action
                          <ActionIcon />
                        </button>
                        <ul
                          className='dropdown-menu actions-dropdown'
                          aria-labelledby={`dropdownMenuButton-${index}`}
                        >
                          <li>
                            <button
                              className='dropdown-item'
                              onClick={() => handleEditShow(audit.id, audit?.name)}
                            >
                              Edit
                            </button>
                          </li>
                          <li>
                            <button
                              className='dropdown-item'
                              onClick={() => handleDeleteShow(audit?.id, audit?.name)}
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      {/* Edit Modal */}
      <Modal show={currentModal === 'edit'} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Audit Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-3'>
            <input
              type='text'
              className='form-control'
              value={auditName}
              onChange={handleNameChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn border cancel-btn' onClick={handleEditClose}>
            Cancel
          </button>
          <button
            className='btn btn-primary'
            onClick={() => handleEditAuditName(auditId, auditName)}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal */}
      <Modal show={currentModal === 'delete'} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Audit Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this audit?</p>
          <div className='mb-3 delete-audit-name'>
            <input type='text' value={auditName} className='form-control' disabled />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn border cancel-btn' onClick={handleDeleteClose}>
            Cancel
          </button>
          <button className='btn btn-danger' onClick={() => handleDeleteAuditName(auditId)}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ManageAuditModal
