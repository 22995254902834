import { useEffect, useState } from 'react'
import { useDeleteProfileImageMutation } from 'App/Modules/Services/Profile'
import Localization from './Localization'
import { useAuth } from '../../../../Auth'
import { toast } from 'react-toastify'
import { Images } from 'Template/assets/Assets'
import { toAbsoluteUrlImage } from '../../../../../../Template/helpers'
import { imageMimeType } from 'config'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const AddSubUser = ({ loading, formik, userData, selectedGroupName }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [image, setImage] = useState<string>('')
  const [file, setFile] = useState(null)
  const [fileDataURL, setFileDataURL] = useState<string | ArrayBuffer | null | undefined>(null)
  const [deleteProfileImage] = useDeleteProfileImageMutation()

  const [hasShownToast, setHasShownToast] = useState(false)
  const changeHandler = (e) => {
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      toast.error('This file format is not allowed!')
      return
    } else {
      setFile(file)
      const reader = new FileReader()
      reader.onload = (event) => {
        setFileDataURL(event?.target?.result)
      }
      reader.readAsDataURL(file)
    }
  }
  useEffect(() => {
    let fileReader,
      isCancel = false
    if (file) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const { result } = e.target
        if (result && !isCancel) {
          setFileDataURL(result)
        }
      }
      fileReader.readAsDataURL(file)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [file])

  useEffect(() => {
    if (currentUser) {
      if (userData) {
        setCurrentUser(userData?.user)
        formik.setValues({
          image: '',
          firstName: '',
          lastName: '',
          title: '',
          phone: '',
          email: '',
          timezone: '',
          dateFormat: '',
          timeFormat: '',
        })
      }
    }
  }, [userData])

  const deleteImage = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e?.preventDefault()
    try {
      const data = await deleteProfileImage('').unwrap()
      if (data?.success) {
        setFileDataURL(null)
        toast.success(data?.message)
      }
    } catch (error: any) {
      if (!error?.data?.success) {
        toast.error(error?.data?.message)
      }
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const formElement = document.querySelector('.form')
      if (formElement) {
        const formBottom = formElement.getBoundingClientRect().bottom
        const windowBottom = window.innerHeight
        if (windowBottom >= formBottom && Object.keys(formik.errors).length > 0 && !hasShownToast) {
          toast.error('Please fill in the required fields before submitting.')
          setHasShownToast(true)
        } else if (windowBottom < formBottom) {
          setHasShownToast(false)
        }
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [formik.errors, hasShownToast])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add User</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-lg-9 p-md-7 p-6'>
            <h3 className='card-title mb-4'>
              <span className='me-2 align-middle'>
                <i className='las la-user fs-1' aria-hidden='true'></i>
              </span>
              <span className='card-label fw-bold fs-3 mb-1'>User</span>
            </h3>
            <div className='ms-md-9'>
              <Row>
                <Col lg='3'>
                  <label htmlFor='photo' className='col-form-label fw-bold fs-6'>
                    Avatar
                  </label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <div className='image-input image-input-outline' data-kt-image-input='true'>
                      <label htmlFor='photo' className='cursor-pointer position-relative'>
                        <img
                          className='image-input-wrapper w-125px h-125px'
                          src={
                            fileDataURL
                              ? fileDataURL.toString()
                              : image
                                ? toAbsoluteUrlImage(image)
                                : Images.Avatar_JPG
                          }
                          alt='profile'
                          crossOrigin='anonymous'
                        />
                        <span className='edit-icon'>
                          <i className='las la-camera fs-2'></i>
                        </span>
                        {image ? (
                          <span onClick={(e) => deleteImage(e)} className='delete-icon'>
                            <i className='las la-trash-alt fs-2'></i>
                          </span>
                        ) : null}
                      </label>
                      <input
                        className='form-control d-none'
                        type='file'
                        name='image'
                        id='photo'
                        accept='.png, .jpg, .jpeg'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          changeHandler(e)
                          formik.setFieldValue(
                            'image',
                            e?.target?.files && e?.target?.files[0]?.type?.match(imageMimeType)
                              ? e.target.files![0]
                              : image
                          )
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className='mb-6'>
                <Col lg='3'>
                  <label htmlFor='fullName' className='col-form-label required fw-bold fs-6'>
                    Full Name
                  </label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row' id='fullName'>
                    <Row>
                      <Col sm='6'>
                        <div className='fv-row'>
                          <input
                            type='text'
                            id='firstName'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            placeholder='First name'
                            {...formik.getFieldProps('firstName')}
                          />
                          {formik.touched.firstName && formik.errors.firstName && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.firstName}</div>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col>
                        <div className='fv-row'>
                          <input
                            type='text'
                            id='lastName'
                            className='form-control form-control-solid'
                            placeholder='Last name'
                            {...formik.getFieldProps('lastName')}
                          />
                          {formik.touched.lastName && formik.errors.lastName && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.lastName}</div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className='mb-6'>
                <Col lg='3'>
                  <label htmlFor='title' className='col-form-label fw-bold fs-6'>
                    Title
                  </label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <input
                      type='text'
                      id='title'
                      className='form-control form-control-solid'
                      placeholder='Title'
                      {...formik.getFieldProps('title')}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='mb-6'>
                <Col lg='3'>
                  <label htmlFor='phone' className='col-form-label fw-bold fs-6'>
                    <span>Phone</span>
                  </label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <input
                      type='tel'
                      id='phone'
                      className='form-control form-control-solid'
                      placeholder='Phone number'
                      {...formik.getFieldProps('phone')}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.phone}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='mb-6'>
                <Col lg='3'>
                  <label htmlFor='email' className='col-form-label fw-bold fs-6'>
                    <span className='required'>Email</span>
                  </label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <input
                      type='email'
                      id='email'
                      className='form-control form-control-solid'
                      placeholder='Email Address'
                      {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.email}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Localization formik={formik} />
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to='/users' className='btn btn-light-primary   main-btn-style me-2'>
              Cancel
            </Link>
            <button
              type='submit'
              className='btn btn-primary main-btn-style'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && 'Add User'}
              {loading && (
                <span className='indicator-progress d-block'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { AddSubUser }
