import { useEffect } from 'react'
import { ProfileDetails } from './Cards/ProfileDetails'
import { TwoFactorAuth } from './Cards/TwoFactorAuth'
import { useDeleteAccountMutation, useGetProfileQuery } from 'App/Modules/Services/Profile'
import SplashScreen from 'App/Loaders/SplashScreen'
import { checkUserUnauthorized } from 'App/Modules/Auth'
import { useAuth } from 'App/Modules/Auth'
import { toast } from 'react-toastify'
import DeleteAccount from 'App/Modules/Apps/CompanySetup/CompnayInfo/DeleteAccount'
import { PersonProfileDetails } from './Cards/PersonProfileDetails'
import { CustomerProfileDetail } from './Cards/CustomerProfileDetail'
import { useLocation } from 'react-router-dom'

export function Settings() {
  const { data, isLoading, isError, error, refetch } = useGetProfileQuery()
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const [deleteAccount] = useDeleteAccountMutation()

  useEffect(() => {
    if (location.pathname === '/account/settings') {
      refetch()
    }
  }, [location.pathname])

  const handleDelete = () => {
    if (currentUser) {
      deleteAccount()
        .then((res: any) => {
          if (res.data) {
            toast.success(res?.data?.message)
            window.location.reload()
          } else {
            toast.error(res?.error?.data?.message)
          }
        })
        .catch((err: any) => {
          toast.error('Error in response!')
          checkUserUnauthorized(err?.data, saveAuth, setCurrentUser, toast)
        })
    }
  }

  useEffect(() => {
    if (isError) {
      const errorData = error as { data: any }
      checkUserUnauthorized(errorData?.data, saveAuth, setCurrentUser, toast)
    }
  }, [isError])

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoading} />
      {!isLoading ? (
        <>
          {currentUser?.userType === 3 ? (
            <>
              <PersonProfileDetails userData={data} />
              <TwoFactorAuth userData={data} />
            </>
          ) : currentUser?.userType === 4 ? (
            <>
              <CustomerProfileDetail userData={data} />
              <TwoFactorAuth userData={data} />
            </>
          ) : currentUser?.userType === 2 ? (
            <>
              <ProfileDetails userData={data} />
              <TwoFactorAuth userData={data} />
            </>
          ) : (
            <>
              <ProfileDetails userData={data} />
              <TwoFactorAuth userData={data} />
              <DeleteAccount handleDelete={handleDelete} />
            </>
          )}
        </>
      ) : null}
    </>
  )
}
