import React, { useRef, useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './AboutUs.css'

const AboutUs: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    const video = videoRef.current

    const handlePlayStateChange = () => {
      setIsPlaying(!!(video && !video.paused))
    }

    const handleCanPlay = () => {
      if (video) {
        video.removeEventListener('canplay', handleCanPlay)
      }
    }

    if (video) {
      video.addEventListener('play', handlePlayStateChange)
      video.addEventListener('pause', handlePlayStateChange)
      video.addEventListener('canplay', handleCanPlay)
    }

    return () => {
      if (video) {
        video.removeEventListener('play', handlePlayStateChange)
        video.removeEventListener('pause', handlePlayStateChange)
        video.removeEventListener('canplay', handleCanPlay)
      }
    }
  }, [])

  const handlePlayPause = () => {
    const video = videoRef.current
    if (video) {
      if (video.readyState >= 2) {
        if (video.paused) {
          video.play()
        } else {
          video.pause()
        }
      }
    }
  }

  return (
    <section className='about-us'>
      <div className='custom-container container'>
        <Row className='flex-lg-row flex-column-reverse'>
          <Col xl="5" lg="6">
            <div className='custom-video position-relative'>
              <video ref={videoRef}>
                <source src={require(`../../../../Videos/FinalCoin.mp4`)} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
              <button
                className={isPlaying ? 'btn video-btn active' : 'btn video-btn'}
                onClick={handlePlayPause}
                id='play'
              >
                {isPlaying ? (
                  <i className='las la-pause'></i>
                ) : (
                  <i className='las la-play ps-1'></i>
                )}
              </button>
            </div>
          </Col>
          <Col xl="7" lg="6">
            <div className='text ps-lg-5 mb-lg-0 mb-sm-10 mb-5'>
              <h2 className='h-main mb-xl-5 mb-4'>What is Asset Rack? </h2>
              <p className='mb-xl-5 mb-4'>
                Asset Rack is a user-friendly platform designed to simplify the tracking, managing, and maintaining of your assets, from business equipment to personal property.
                With real-time updates and secure data storage, our intuitive interface ensures all your valuable assets are organized and accessible.
              </p>
              <p className='mb-xl-5 mb-4'>
                Empower your decisions with detailed reporting and reduce costs while enhancing productivity. Asset Rack offers a comprehensive solution for individuals and
                businesses alike.
              </p>
              <p className='mb-xl-5 mb-4'>
                Get Started Now! Create your account and take control of your assets today!
              </p>
              <div className='button-group d-flex justify-content-lg-start justify-content-center flex-wrap pt-4'>
                <a href='#faqs' className='main-btn main-btn-yellow m-1'>
                  Contact with Experts
                </a>
                <a href='/auth/registration' className='main-btn main-btn-white m-1'>
                  Join us Today
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default AboutUs
