import { Images } from 'Template/assets/Assets'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './TrustedBy.css'

function TrustedBy() {
  return (
    <section className='trusted-by-section position-relative mb-5'>
      <div className='position-relative z-2'>
        <div className='custom-container container'>
          <Row className='align-items-center'>
            <Col lg="3">
              <div className='text mb-lg-0 mb-5'>
                <span className='ultra-mini-heading mb-2'>Who endorse our work</span>
                <h2 className='h-main'>Trusted By:</h2>
              </div>
            </Col>
            <Col lg="9">
              <div className='image-section'>
                <ul className='list-unstyled d-flex mb-0 align-items-center justify-content-start'>
                  <li>
                    <div className='image'>
                      <img
                        src={Images.Trusted_1}
                        alt='company'
                        width='100%'
                        height='100%'
                        loading='lazy'
                      />
                    </div>
                  </li>
                  <li>
                    <div className='image'>
                      <img
                        src={Images.Trusted_2}
                        alt='company'
                        width='100%'
                        height='100%'
                        loading='lazy'
                      />
                    </div>
                  </li>
                  <li>
                    <div className='image'>
                      <img
                        src={Images.Trusted_3}
                        alt='company'
                        width='100%'
                        height='100%'
                        loading='lazy'
                      />
                    </div>
                  </li>
                  <li>
                    <div className='image'>
                      <img
                        src={Images.Trusted_4}
                        alt='company'
                        width='100%'
                        height='100%'
                        loading='lazy'
                      />
                    </div>
                  </li>
                  <li>
                    <div className='image'>
                      <img
                        src={Images.Trusted_5}
                        alt='company'
                        width='100%'
                        height='100%'
                        loading='lazy'
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  )
}

export default TrustedBy
