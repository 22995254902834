import { useEffect, useState } from 'react'
import { PageTitle } from '../../../Template/layout/core'
import { StatisticsWidget5 } from '../../../Template/partials/widgets'
import { useGetDashboardStatsQuery } from 'App/Modules/Services/Dashboard'
import { toast } from 'react-toastify'
import SplashScreen from 'App/Loaders/SplashScreen'
import Loader from 'App/Loaders/BeatLoader'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { useLocation } from 'react-router-dom'

const DashboardPage = ({ stats }) => {
  const { currentUser } = useAuth()
  const staffUser = currentUser?.userType === 2
  const ownerUser = currentUser?.userType === 1
  const permission = currentUser?.SecurityGroup

  return (
    <>
      {(ownerUser || (staffUser && permission?.viewDashboard)) && (
        <div className='row g-5 g-xl-8'>
          {(ownerUser || (staffUser && permission?.assetCount)) && (
            <div className='col-xl-4'>
              <StatisticsWidget5
                className='card-xl-stretch mb-8'
                svgIcon='la la-puzzle-piece fs-2'
                color='body-white'
                iconColor='primary'
                title='Assets'
                records={stats?.assets || 0}
                description='List of Assets'
                titleColor='gray-900'
                descriptionColor='gray-400'
                link='/assets'
              />
            </div>
          )}
          {(ownerUser || (staffUser && permission?.personCount)) && (
            <div className='col-xl-4'>
              <StatisticsWidget5
                className='card-xl-stretch mb-8'
                svgIcon='la la-user-friends fs-2'
                color='body-white'
                iconColor='primary'
                title='Users'
                records={stats?.users || 0}
                description='List of Users'
                titleColor='gray-900'
                descriptionColor='gray-400'
                link='/users'
              />
            </div>
          )}
          {(ownerUser || (staffUser && permission?.userCount)) && (
            <div className='col-xl-4'>
              <StatisticsWidget5
                className='card-xl-stretch mb-5 mb-8'
                svgIcon='la la-user-tie fs-2'
                color='body-white'
                iconColor='primary'
                title='Employees'
                records={stats?.employees || 0}
                description='List of Employees'
                titleColor='gray-900'
                descriptionColor='gray-400'
                link='/persons'
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

const DashboardWrapper = () => {
  const { saveAuth, setCurrentUser } = useAuth()
  const location = useLocation()
  const { isLoading, data, error, refetch } = useGetDashboardStatsQuery()
  const [stats, setStats] = useState<object>({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      refetch()
    }
  }, [location.pathname])

  useEffect(() => {
    switch (true) {
      case !!data:
        setStats(data.stats)
        setLoading(false)
        break

      case !!error:
        const err: any = error
        if (err) {
          toast.error(err?.data?.message)
          setLoading(false)
          checkUserUnauthorized(err?.data, saveAuth, setCurrentUser, toast)
        }
        break

      default:
        // Handle any other cases if needed
        break
    }
  }, [data, error])

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoading} />
      {!isLoading ? (
        <>
          <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
          <DashboardPage stats={stats} />{' '}
        </>
      ) : null}
      <Loader loading={loading} />
    </>
  )
}

export { DashboardWrapper }
