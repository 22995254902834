import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { PageTitle } from 'Template/layout/core'
import * as Yup from 'yup'
import SplashScreen from 'App/Loaders/SplashScreen'
import NewAuditModal from './modals/NewAuditModal'
import ManageAuditModal from './modals/ManageAuditModal'
import MapModal from './modals/MapModal'
import AssetMinimalDetailModal from 'App/Pages/Checkout/Modal/AssetMinimalDetailModal'
import './Audit.css'
import { useGetAllAuditNamesQuery } from '../Services/AuditName'
import { useAuth } from '../Auth'
import { useGetAllSitesQuery } from '../Services/Sites'
import { useGetAllLocationsBySiteQuery } from '../Services/Locations'
import Select from 'react-select'
import { useAddAuditMutation, useGetAllPendingAssetQuery } from '../Services/Audit'
import { useGetAllCategoriesQuery } from '../Services/Categories'
import { toast } from 'react-toastify'
import Loader from 'App/Loaders/BeatLoader'
import ReactDatePicker from 'react-datepicker'
interface Asset {
  id: number
  description: string
  status: string
  assignedTo: string
  site: string
  location: string
  userAssets: any
}

const Audit: React.FC = () => {
  const { currentUser } = useAuth()
  const [newAuditModal, setNewAuditModal] = useState<boolean>(false)
  const handleClose = (): void => setNewAuditModal(false)
  const handleNewAuditModal = (): void => setNewAuditModal(true)
  const [manageAuditModal, setManageAuditModal] = useState<boolean>(false)
  const closeManageAuditModal = (): void => setManageAuditModal(false)
  const handleManageAuditModal = (): void => setManageAuditModal(true)
  const [pendingAudit, setPendingAudit] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedAssets, setSelectedAssets] = useState<string[]>([])
  const [assetMinimalDetail, setAssetMinimalDetail] = useState<boolean>(false)
  const [mapShow, setMapShow] = useState(false)
  const handleMapShow = () => setMapShow(true)
  const handleMapClose = () => setMapShow(false)
  const [siteList, setSiteList] = useState([])
  const [locationList, setLocationList] = useState<any>([])
  const [categoryList, setCategoryList] = useState<any>([])
  const [auditNameList, setAuditNameList] = useState<any>([])
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedAuditName, setSelectedAuditName] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [auditAssets, setAuditAssets] = useState<any>([])
  const [refresh, setRefresh] = useState(0)
  const [selectedSite, setSelectedSite] = useState(null)
  const [assetIdsInput, setAssetIdsInput] = useState('')
  const [assetId, setAssetId] = useState<number>()
  const [searchAssets, setSearchAssets] = useState<any>({
    siteId: '',
    locationId: '',
    categoryId: '',
    assetTagIds: '',
    auditNameId: '',
  })
  const {
    data: auditNameData,
    refetch,
    isLoading,
  } = useGetAllAuditNamesQuery({
    userId: currentUser?.id,
  })

  const { data: siteData, isLoading: isSiteLoading } = useGetAllSitesQuery({
    userId: currentUser?.id,
  })

  const { data: locationData, isLoading: isLocationLoading } = useGetAllLocationsBySiteQuery(
    { userId: currentUser?.id, id: searchAssets?.siteId },
    { skip: !searchAssets?.siteId }
  )

  const {
    data: getpendingAssets,
    isSuccess,
    isError,
    isLoading: isPendingAssetLoading,
  } = useGetAllPendingAssetQuery({
    body: new URLSearchParams(searchAssets).toString(),
  })

  const { data: categoryData } = useGetAllCategoriesQuery({ userId: currentUser?.id, page: 1 })
  const [addAudit] = useAddAuditMutation()

  const formik = useFormik({
    initialValues: {
      auditNameId: '',
      siteId: '',
      locationId: '',
      categoryId: '',
      assetIds: [],
      auditDate: '',
      notes: '',
      map: false,
      latitude: '',
      longitude: '',
      createdBy: currentUser?.id,
    },
    validationSchema: Yup.object({
      auditNameId: Yup.string().required('Audit name reference is required.'),
      siteId: Yup.string().required('Site reference is required.'),
      locationId: Yup.string().required('Location reference is required.'),
      auditDate: Yup.date().required('Audit Date is required.'),
      notes: Yup.string().max(250, 'Note should not exceed 250 characters'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const result = await addAudit(values)
        if (result?.data?.success) {
          toast.success(result?.data?.message)
          removeSelectedAssets()
          refetch()
          setLoading(false)
          setSelectedAssets([])
        }
      } catch (error: any) {
        throw new Error(error)
      }
    },
  })

  const handleRefresh = () => {
    if (formik.values.siteId === '' || formik.values.locationId === '') {
      formik.setFieldError('siteId', 'Site reference is required.')
      formik.setFieldError('locationId', 'Location reference is required.')
    } else {
      setRefresh((prev) => prev + 1)
      setPendingAudit(true)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      setAuditAssets(getpendingAssets?.userAssets)
    }
  }, [isSuccess, isError, getpendingAssets, refresh])

  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        setLoading(true)
        if (siteData) {
          const options = siteData?.sites?.map((item) => ({
            value: item?.id,
            label: item?.name,
          }))
          setSiteList(options)
        }
      } catch (error: any) {
        throw new Error(error)
      } finally {
        setLoading(false)
      }
    }

    fetchSiteData()
  }, [siteData, refresh])

  useEffect(() => {
    const fetchLocationData = async () => {
      setLoading(true)
      try {
        if (locationData) {
          const options = locationData?.locations?.map((item) => ({
            value: item?.id,
            label: item?.location,
          }))
          setLocationList(options)
        }
      } catch (error: any) {
        throw new Error(error)
      } finally {
        setLoading(false)
      }
    }

    fetchLocationData()
  }, [locationData, refresh])

  useEffect(() => {
    if (categoryData) {
      const allCategoriesOption = {
        value: '',
        label: 'All Categories',
      }

      const options = categoryData?.category?.map((item) => ({
        value: item?.id,
        label: item?.category,
      }))

      setCategoryList([allCategoriesOption, ...options])
    }
  }, [categoryData, refresh])

  useEffect(() => {
    if (auditNameData) {
      const options = auditNameData?.auditNames?.map((item) => ({
        value: item?.id,
        label: item?.name,
        status: item?.status,
      }))
      setAuditNameList(options)
      if (options.length > 0) {
        const newOption = options[options.length - 1] && options[options.length - 1].status === true

        setSelectedAuditName(newOption && options[options.length - 1])
        setSearchAssets((prevState) => ({
          ...prevState,
          auditNameId: options[options?.length - 1]?.value,
        }))
        formik.setFieldValue('auditNameId', options[options?.length - 1]?.value)
      }
    }
  }, [auditNameData, refresh, refetch])

  useEffect(() => {
    if (auditNameData?.auditNames.length === 0) {
      setSelectedAuditName(null)
    }
  }, [auditNameData])

  useEffect(() => {
    formik.setFieldValue('assetIds', selectedAssets)
  }, [selectedAssets])

  useEffect(() => {
    if (auditAssets?.length === 0) {
      setSelectedAssets([])
    }
  }, [auditAssets])

  const handleCheckboxChange = (e) => {
    setIsChecked(!isChecked)
    formik.setFieldValue('map', e.target.checked)
  }

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedAssets([])
    } else {
      setSelectedAssets(auditAssets?.map((asset) => asset.id) || [])
    }
    setSelectAll(!selectAll)
  }

  useEffect(() => {
    if (auditAssets?.length > 0 && selectedAssets.length === auditAssets.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedAssets, auditAssets])

  const assetMinimalHandler =
    (asset: Asset) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      setAssetMinimalDetail(true)
      setAssetId(asset?.id)
    }
  const handleModalClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setAssetMinimalDetail(false)
  }

  const auditNameStatus = auditNameList?.filter((audit) => audit.status === true)

  const handleSelectChange = (selectedOption) => {
    setSelectedSite(selectedOption)
    setSearchAssets((prevState) => ({
      ...prevState,
      siteId: selectedOption?.value,
    }))
    formik.setFieldValue('siteId', selectedOption?.value)
  }

  const handleLocationFilter = (selectedOption) => {
    setSelectedLocation(selectedOption)
    setSearchAssets((prevState) => ({
      ...prevState,
      locationId: selectedOption?.value,
    }))
    formik.setFieldValue('locationId', selectedOption?.value)
  }

  const handleCategoryFilter = (selectedOption) => {
    setSelectedCategory(selectedOption)
    setSearchAssets((prevState) => ({
      ...prevState,
      categoryId: selectedOption?.value,
    }))
    formik.setFieldValue('categoryId', selectedOption?.value)
  }

  const handleAuditNameFilter = (selectedOption) => {
    setSelectedAuditName(selectedOption)
    setSearchAssets((prevState) => ({
      ...prevState,
      auditNameId: selectedOption?.value,
    }))
    formik.setFieldValue('auditNameId', selectedOption?.value)
  }

  const handleAssetIdsChange = (event) => {
    setAssetIdsInput(event.target.value)
  }

  const handleAddToList = async () => {
    if (assetIdsInput) {
      setSearchAssets((prevState) => ({
        ...prevState,
        assetTagIds: assetIdsInput,
      }))
    }
  }
  const handleAssetChange = (id) => {
    setSelectedAssets((prevSelectedAssets) =>
      prevSelectedAssets.includes(id)
        ? prevSelectedAssets.filter((assetId) => assetId !== id)
        : [...prevSelectedAssets, id]
    )
  }

  const removeSelectedAssets = () => {
    setAuditAssets((prevAssets) => prevAssets.filter((asset) => !selectedAssets.includes(asset.id)))
    setSelectedAssets([])
    setSelectAll(false)
  }

  const handleResetAudit = () => {
    setSelectedSite(null)
    setSelectedLocation(null)
    setSelectedAssets([])
    setPendingAudit(false)
    formik.setFieldValue('siteId', '')
    formik.setFieldValue('locationId', '')
  }

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoading} />

      <PageTitle breadcrumbs={[]}>Audit</PageTitle>
      {!isLoading && (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header border-0'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>
                <span className='me-2 align-middle'>
                  <i className='la la-file-alt fs-2'></i>
                </span>
                Audit
              </h3>
            </div>
          </div>
          <div id='kt_account_profile_details' className='collapse show'>
            <div className='form'>
              <div className='card-body border-top p-lg-9 p-md-7 p-6'>
                <form className='audit-form' onSubmit={formik.handleSubmit}>
                  <div className='audit-container'>
                    <div className='audit-section'>
                      <h5 className='no-of-step'>Step 1: Set Audit Name</h5>
                      <div className='audit-row d-md-flex align-item-center justify-content-md-center justify-content-start d-sm-flex d-sm-flex-column'>
                        <label htmlFor='auditName' className='form-label'>
                          Audit Name *
                        </label>
                        <div className='form-group  mb-3 mb-md-0 w-100 w-md-auto'>
                          <Select
                            name='auditNameId'
                            onChange={handleAuditNameFilter}
                            value={selectedAuditName}
                            options={auditNameStatus}
                            isSearchable={true}
                            placeholder='Select Audit Name'
                            classNamePrefix='custom-select'
                            className='custom-react-select w-100'
                          />
                          {formik.touched.auditNameId && formik.errors.auditNameId ? (
                            <div className='invalid-feedback'>{formik.errors.auditNameId}</div>
                          ) : null}
                        </div>
                        <div className='d-md-inline d-sm-flex flex-column audit-modals'>
                          <button
                            type='button'
                            className='btn btn-light-primary main-btn-style m-1'
                            onClick={handleNewAuditModal}
                          >
                            <i className='la la-plus fs-1'></i>New Audit
                          </button>
                          <button
                            type='button'
                            className='btn btn-light-primary main-btn-style m-1'
                            onClick={handleManageAuditModal}
                          >
                            <i className='la la-cog fs-1'></i>Manage Audits
                          </button>
                          {newAuditModal && (
                            <NewAuditModal
                              newAuditModal={newAuditModal}
                              handleClose={handleClose}
                            />
                          )}
                          <ManageAuditModal
                            show={manageAuditModal}
                            handleClose={closeManageAuditModal}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='audit-section'>
                      <h5 className='no-of-step'>Step 2: Audit Site and Location</h5>
                      <div className='audit-section step-2 d-flex flex-column align-items-center justify-content-center'>
                        <div className='audit-row w-100 d-flex justify-content-center'>
                          <div className='form-group col-12 col-md-6'>
                            <label htmlFor='auditSite' className='form-label'>
                              Audit Site *
                            </label>
                            <Select
                              name='siteId'
                              onChange={handleSelectChange}
                              value={selectedSite}
                              options={siteList}
                              isSearchable={true}
                              placeholder='Select a site'
                              classNamePrefix='custom-select'
                              className='custom-react-select w-100'
                            />
                            {formik.touched.siteId && formik.errors.siteId ? (
                              <div className='invalid-feedback'>{formik.errors.siteId}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className='audit-row w-100 d-flex justify-content-center mt-3'>
                          <div className='form-group col-12 col-md-6'>
                            <label htmlFor='auditLocation' className='form-label'>
                              Audit Location *
                            </label>
                            <Select
                              name='locationId'
                              onChange={handleLocationFilter}
                              options={locationList}
                              value={selectedLocation}
                              isSearchable={true}
                              placeholder='Select a Location'
                              classNamePrefix='custom-select'
                              className='custom-react-select w-100'
                            />
                            {formik.touched.locationId && formik.errors.locationId ? (
                              <div className='invalid-feedback'>{formik.errors.locationId}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='audit-section'>
                      <h5 className='no-of-step'>Step 3: Add Assets to List</h5>
                      <p>Add assets to the list for the above selected Audit Site and Location.</p>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group mb-3 custom-border'>
                            <div className='category-form'>
                              <label htmlFor='category' className='form-label me-4'>
                                Category
                              </label>
                              <div>
                                <Select
                                  name='categoryId'
                                  onChange={handleCategoryFilter}
                                  options={categoryList}
                                  isSearchable={true}
                                  placeholder='Select a Category'
                                  classNamePrefix='custom-select'
                                  className='custom-react-select w-100'
                                />
                                <p>
                                  Optionally you can filter the results by selecting a category.
                                </p>
                              </div>
                              <div className=''>
                                <button
                                  onClick={handleRefresh}
                                  type='button'
                                  className='btn btn-light-primary main-btn-style  mt-2 mb-1'
                                  disabled={!formik.values.siteId}
                                >
                                  Refresh List
                                </button>
                                <p>
                                  This button adds/refreshes assets in the 'Assets Pending Audit'
                                  list below.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label htmlFor='assetIds' className='form-label'>
                              If you have a list of assets to audit, enter Asset IDs comma separated
                              or one entry in each line.
                            </label>
                            <textarea
                              id='assetIds'
                              name='assetIds'
                              rows={4}
                              className='form-control mb-2'
                              onChange={handleAssetIdsChange}
                              value={assetIdsInput}
                            ></textarea>
                          </div>
                          <button
                            type='button'
                            className='btn btn-light-primary main-btn-style  mt-2 mb-1 mx-md-auto d-flex align-items-center justify-content-center'
                            onClick={handleAddToList}
                          >
                            Add to List
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {pendingAudit && (
                    <div>
                      <div className='mb-sm-3 mb-md-0'>
                        <h3>Assets Pending Audit</h3>
                      </div>
                      <div className='audit-table'>
                        <div className='table-responsive mb-md-3 mb-5'>
                          <table className='table table-bordered'>
                            <thead>
                              <tr>
                                <th scope='col'>
                                  <label className='custom-checkbox d-flex justify-content-center'>
                                    <input
                                      type='checkbox'
                                      checked={
                                        auditAssets.length > 0 &&
                                        selectedAssets.length === auditAssets.length
                                      }
                                      onChange={handleSelectAllChange}
                                    />
                                    <span className='checkmark'></span>
                                  </label>
                                </th>
                                <th scope='col'>Asset Tag ID</th>
                                <th scope='col'>Description</th>
                                <th scope='col'>Status</th>
                                <th scope='col'>Assigned to</th>
                                <th scope='col'>Site</th>
                                <th scope='col'>Location</th>
                              </tr>
                            </thead>
                            <tbody>
                              {auditAssets && auditAssets?.length > 0
                                ? auditAssets?.map((asset) => (
                                    <tr key={asset.assetTagId} role='row'>
                                      <td role='cell'>
                                        <label className='custom-checkbox'>
                                          <input
                                            type='checkbox'
                                            checked={selectedAssets.includes(asset?.id)}
                                            onChange={() => handleAssetChange(asset?.id)}
                                          />
                                          <span className='checkmark'></span>
                                        </label>
                                      </td>
                                      <td className='table-elipses'>
                                        <button
                                          type='button'
                                          onClick={assetMinimalHandler(asset)}
                                          className='btn btn-link asset-linking'
                                        >
                                          {asset?.assetTagId}
                                        </button>
                                      </td>
                                      <td className='table-elipses'>
                                        <span>{asset?.description}</span>
                                      </td>
                                      <td className='table-elipses'>
                                        <span>{asset?.status}</span>
                                      </td>
                                      <td className='table-elipses'>
                                        <span>
                                          {asset?.assigneeType === 1 ? (
                                            <span>
                                              {`${asset?.assignedPerson?.firstName || ''} ${
                                                asset?.assignedPerson?.lastName || ''
                                              }`}
                                            </span>
                                          ) : asset?.assigneeType === 2 ? (
                                            <span>
                                              {`${asset?.assignedSite?.name || ''} ${
                                                asset?.assignedSite?.name &&
                                                asset?.location?.location
                                                  ? '/'
                                                  : ''
                                              } ${asset?.location?.location || ''}`}
                                            </span>
                                          ) : (
                                            <span></span>
                                          )}
                                        </span>
                                      </td>
                                      <td className='table-elipses'>
                                        <span>{asset.site?.name}</span>
                                      </td>
                                      <td className='table-elipses'>
                                        <span>{asset?.location?.location}</span>
                                      </td>
                                    </tr>
                                  ))
                                : null}
                            </tbody>
                          </table>
                          {assetMinimalDetail && (
                            <AssetMinimalDetailModal
                              show={assetMinimalDetail}
                              onHide={handleModalClose}
                              assetId={assetId}
                            />
                          )}
                        </div>
                        {auditAssets.length > 0 && (
                          <button
                            type='button'
                            onClick={removeSelectedAssets}
                            className='remove-asset-btn d-flex align-items-center justify-content-center'
                          >
                            <i className='la la-times'></i> Remove Asset
                          </button>
                        )}
                      </div>
                      <div className=' my-4 py-5 audit-table-form'>
                        <div>
                          <h5>Audit</h5>
                        </div>
                        <div>
                          <p className='ms-4'>
                            To audit, check assets in the 'Asset Pending Audit' list above, define
                            'Audit Date' and click on 'Audit' button. Audited assets will disappear
                            from the list.
                          </p>
                          <div>
                            <div className='row'>
                              <div className='col-md-6'></div>
                              <div className='col-md-6'>
                                <div className='form-group mb-2'>
                                  <div className='form-check'>
                                    <input
                                      name='map'
                                      className='form-check-input'
                                      type='checkbox'
                                      id='recordGps'
                                      checked={formik.values.map}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label className='form-check-label ms-2'>
                                      Record GPS Coordinates
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 '>
                                <div className='category-form'>
                                  <div className='form-group d-flex flex-column'>
                                    <label className=' col-form-label'>Audit Date *</label>
                                    <ReactDatePicker
                                      className='form-control'
                                      selected={
                                        formik.values.auditDate
                                          ? new Date(formik.values.auditDate)
                                          : null
                                      }
                                      onChange={(date) => formik.setFieldValue('auditDate', date)}
                                      onBlur={formik.handleBlur}
                                      name='auditDate'
                                      dateFormat='Pp'
                                      showTimeSelect
                                      minDate={new Date()}
                                    />
                                    {formik.touched.auditDate && formik.errors.auditDate ? (
                                      <div className='invalid-feedback'>
                                        {formik.errors.auditDate}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className='form-group'>
                                    <label className=' col-form-label'>Audit Notes</label>
                                    <textarea
                                      name='notes'
                                      className='form-control'
                                      id='auditNotes'
                                      value={formik.values.notes}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    ></textarea>
                                    {formik.touched.notes && formik.errors.notes && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          {String(formik.errors.notes)}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='category-form'>
                                  {isChecked && (
                                    <>
                                      <div className='form-group d-none'>
                                        <button
                                          onClick={handleMapShow}
                                          type='button'
                                          className='btn btn-light-primary main-btn-style m-1  bg-transparent'
                                        >
                                          <i className='la la-map-marker fs-1 text-danger'></i> Mark
                                          on Map
                                        </button>
                                        <span className='ms-2'>or enter manually:</span>
                                      </div>
                                      <div className='form-group'>
                                        <label className=' col-form-label'>Latitude</label>
                                        <input
                                          name='latitude'
                                          value={formik.values.latitude}
                                          type='text'
                                          className='form-control'
                                          id='latitude'
                                          onChange={formik.handleChange}
                                        />
                                      </div>
                                      <div className='form-group'>
                                        <label className=' col-form-label'>Longitude</label>
                                        <input
                                          name='longitude'
                                          value={formik.values.longitude}
                                          type='text'
                                          className='form-control'
                                          id='longitude'
                                          onChange={formik.handleChange}
                                        />
                                      </div>
                                      <MapModal mapShow={mapShow} handleMapClose={handleMapClose} />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex align-items-center justify-content-end audit-control'>
                        <button
                          type='button'
                          className='btn border cancel-btn'
                          onClick={handleResetAudit}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={selectedAssets.length === 0}
                          type='submit'
                          className='btn btn-primary ms-3'
                        >
                          Audit
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
          <Loader loading={loading} />
        </div>
      )}
    </>
  )
}

export default Audit
