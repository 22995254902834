import React, { useState, useEffect } from 'react'
import { useGetAllSitesQuery } from '../../Modules/Services/Sites'
import { useGetAllLocationsBySiteQuery } from '../../Modules/Services/Locations'
import { useGetAllDepartmentsQuery } from '../../Modules/Services/Departments'
import { useAddCheckoutMutation } from '../../Modules/Services/Checkout'
import { useGetAllAssetsQuery } from 'App/Modules/Services/Assets'
import { useGetAllPersonsQuery, useGetPersonsQuery } from 'App/Modules/Services/Persons'
import { CheckoutInterface, defaultCheckoutDetail as initialValues } from './Core/Model'
import { useAuth } from '../../Modules/Auth'
import { checkUserUnauthorized } from '../../Modules/Auth'
import { PageTitle } from '../../../Template/layout/core'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { getAssetStatusClass, multiSpace } from 'config'
import { useNavigate, Link } from 'react-router-dom'
import DuplicateReserveModal from './Modal/DuplicateReserveModal'
import CheckoutModal from './Modal/CheckoutModal'
import PersonsModal from 'App/Modules/Apps/CompanySetup/Persons/Components/PersonsModal'
import SplashScreen from 'App/Loaders/SplashScreen'
import Loader from 'App/Loaders/BeatLoader'
import Select from 'react-select'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { refresh, selectValue } from 'App/Store'

const checkedoutSchema = Yup.object().shape({
  checkoutDate: Yup.date()
    .nullable()
    .min(new Date(new Date().setHours(0, 0, 0, 0)), 'Checkout Date cannot be a past date'),
  dueDate: Yup.date()
    .nullable()
    .min(new Date(new Date().setHours(0, 0, 0, 0)), 'Due Date cannot be a past date'),
  assigneeType: Yup.number().required('Required'),
  personId: Yup.string().test('person-required', 'Person is required', function (value) {
    const { assigneeType } = this.parent
    if (assigneeType === 1) {
      return !!value
    }
    return true
  }),
  siteId: Yup.string().test('siteId-required', '', function (value) {
    const { assigneeType } = this.parent
    if (assigneeType === 2) {
      return !!value
    }
    return true
  }),
  notes: Yup.string().max(250, 'Note should not exceed 250 characters'),
})

const Checkout = () => {
  const [assignOptions, setAssignOptions] = useState<any>([])
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const dispatch = useDispatch()
  const value = useSelector(selectValue)
  const permission = currentUser?.SecurityGroup
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4
  const ownerUser = currentUser?.userType === 1
  const staffUser = currentUser?.userType === 2
  const navigate = useNavigate()
  const [assets, setAssets] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const [showCheckoutModal, setShowCheckoutModal] = useState<boolean>(false)
  const [showPersonModal, setShowPersonModal] = useState<boolean>(false)
  const [reserveModalData, setReserveModalData] = useState<any>({})
  const [duplicateReserveModal, setDuplicateReserveModal] = useState(false)
  const [reservedData, setReservedData] = useState<any>([])
  const [pendingAssets, setPendingAssets] = useState<any>([])
  const [selectedAssets, setSelectedAssets] = useState<any>([])
  const [selectAll, setSelectAll] = useState(false)
  const [personCheckout, setPersonCheckout] = useState(true)
  const [personId, setPersonId] = useState('')
  const [emailEnabled, setEmailEnabled] = useState(false)
  const [selected, setSelected] = useState<any>([])
  const [deleteDataArr, setDeleteDataArr] = useState<number[]>([])
  const [siteId, setSiteId] = useState<any>()
  const [customErrors, setCustomErrors] = useState({
    assignToField: '',
    siteField: '',
  })
  const [isNewPersonAdded, setIsNewPersonAdded] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [addCheckout] = useAddCheckoutMutation()

  const { data: userSitesList } = useGetAllSitesQuery({ userId: currentUser?.id })
  const { data: userLocationList } = useGetAllLocationsBySiteQuery(
    { userId: currentUser?.id, id: siteId },
    { skip: !siteId }
  )
  const { data: userDepartmentList } = useGetAllDepartmentsQuery({ userId: currentUser?.id })
  const { data: assetsData, isLoading } = useGetAllAssetsQuery({
    body: new URLSearchParams({}).toString(),
    page: page,
    limit: limit,
    keys: '',
  })
  const { data: userPersonsList } = useGetAllPersonsQuery(
    { search: selectedOption },
    { skip: !selectedOption }
  )
  const { data: personData } = useGetPersonsQuery({
    skip: personUser || customerUser || (staffUser && !permission?.viewPerson),
  })

  useEffect(() => {
    if (value) {
      setPersonCheckout(true)
      dispatch(refresh(!value))
    }
  }, [value])

  const setOptionsForSelect = () => {
    const options: any = []
    personData?.persons?.forEach((person) => {
      options.push({
        label: (
          <div className='d-flex flex-column'>
            <span className='personFullName'>{`${person?.firstName} ${person?.lastName}`}</span>
            <span className='personEmail'> {person?.email}</span>
          </div>
        ),
        value: person?.email,
        id: person?.id,
      })
    })
    setAssignOptions(options)
  }

  useEffect(() => {
    if (isNewPersonAdded) {
      formik.setFieldValue('personId', userPersonsList?.persons[0]?.id)
    }
  }, [personData])

  useEffect(() => {
    setDataLoading(false)
  }, [userLocationList])

  useEffect(() => {
    setDataLoading(false)
    setOptionsForSelect()
  }, [personData])

  const handleSiteClick = ({ target: { value } }) => {
    if (value === '') {
      setCustomErrors({ siteField: 'Site is required', assignToField: '' })
      setSiteId(0)
    } else {
      setDataLoading(true)
      setSiteId(value)
      setCustomErrors({ siteField: '', assignToField: '' })
      setDataLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: checkedoutSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        if (currentUser) {
          switch (true) {
            case personCheckout && !selectedOption:
              setCustomErrors({ assignToField: 'Person is required', siteField: '' })
              setLoading(false)
              return undefined

            case !personCheckout && values.siteId === undefined:
              setCustomErrors({ siteField: 'Site is required', assignToField: '' })
              setLoading(false)
              return undefined

            default:
              setCustomErrors({ assignToField: '', siteField: '' })
              handleCheckoutSubmit(values)
              break
          }
        }
      } catch (error: any) {
        toast.error(error?.data?.message)
      }
    },
  })

  const handleSelectChange = (selectedOption) => {
    formik.setFieldValue('personId', selectedOption?.id)
    setSelectedOption(selectedOption || '')
    if (selectedOption === null) {
      setCustomErrors({ assignToField: 'Person is required', siteField: '' })
    } else {
      setCustomErrors({ assignToField: '', siteField: '' })
    }
  }

  useEffect(() => {
    if (isNewPersonAdded) {
      formik.setFieldValue(
        'assignTo',
        personData?.persons ? personData?.persons[personData?.persons?.length - 1]?.id : ''
      )
    }
  }, [personData])

  useEffect(() => {
    if (assetsData) {
      getAssetListing()
      setDataLoading(false)
    }
  }, [assetsData])

  useEffect(() => {
    if (assets && pendingAssets && showCheckoutModal == false) {
      handlePendingList()
    }
    setDataLoading(false)
  }, [assets, showCheckoutModal])

  const getAssetListing = async () => {
    setAssets(assetsData?.assets)
  }

  const handlePendingList = () => {
    const data = assets?.filter((asset: any) => pendingAssets.includes(asset.id))
    setSelectedAssets(data)
    setDeleteDataArr([])
  }

  const handleCheckoutSubmit = async (values: CheckoutInterface) => {
    setDuplicateReserveModal(false)
    const data = {
      checkoutDate: values?.checkoutDate == '' ? null : values?.checkoutDate,
      assigneeType: values.assigneeType,
      dueDate: values?.dueDate == '' ? null : values?.dueDate,
      notes: values?.notes ? values?.notes?.trim().replace(multiSpace, ' ') : '',
      personId: values.personId,
      locationId: values?.locationId,
      siteId: values?.siteId,
      departmentId: values?.departmentId,
      assetId: pendingAssets,
      overrideReservation: values?.overrideReservation,
      sendEmail: emailEnabled,
    }
    try {
      const result = await addCheckout(data).unwrap()
      if (result) {
        if (result.status === 409) {
          setReservedData(result?.reservedAssets)
          handleDuplicateReserveModal()
          setReserveModalData(data)
          // getAssetListing()
        } else {
          toast.dismiss()
          toast.success(result?.message ? result?.message : result?.data?.message)
          setSelectedAssets([])
          setPersonCheckout(false)
          formik.resetForm()
          navigate('/checkout')
          formik.setFieldValue('personId', '')
          formik.setFieldValue('notes', '')
          formik.setFieldValue('siteId', '')
          formik.setFieldValue('locationId', '')
          formik.setFieldValue('departmentId', '')
          setSelectedOption(null)
        }
      }
    } catch (error: any) {
      if (error?.data?.reservedAssets) {
        setReservedData(error?.data?.reservedAssets)
        handleDuplicateReserveModal()
        setReserveModalData(data)
        // getAssetListing()
      } else {
        toast.error(error?.data?.message)
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleShowCheckoutModal = () => {
    setDataLoading(true)
    setShowCheckoutModal(true)
    setDataLoading(false)
  }

  const handleCloseCheckoutModal = () => {
    setShowCheckoutModal(false)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setDeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setDeleteDataArr([])
    }
  }

  useEffect(() => {
    if (formik.values.assigneeType === 1) setPersonCheckout(true)
    else if (formik.values.assigneeType === 2) setPersonCheckout(false)
  }, [formik.values.assigneeType])

  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      const updatedSelected = prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
      const isAllSelected = updatedSelected.length === selectedAssets?.length
      setSelectAll(isAllSelected)
      return updatedSelected
    })
    setDeleteDataArr((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  const deleteSelectedAssests = async () => {
    const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id))
    setSelectedAssets(data)
    setDeleteDataArr([])
    setSelectAll(false)
    setSelected([])
  }

  const handleClosePersonModal = () => {
    setShowPersonModal(false)
  }

  const handleShowPersonModal = () => {
    setShowPersonModal(true)
    setIsNewPersonAdded(true)
  }

  const handleDuplicateReserveModal = () => {
    setDuplicateReserveModal(true)
  }

  const handleCloseDuplicateReserveModal = () => {
    setDuplicateReserveModal(false)
  }

  const handleCancel = () => {
    setSelectedAssets([])
    setPendingAssets([])
    setShowCheckoutModal(false)
    setPersonCheckout(false)
    setSiteId(undefined)
    formik.resetForm()
    setCustomErrors({ siteField: '', assignToField: '' })
  }

  const handleAssigeeTypeChange = () => {
    setSiteId(undefined)
    setEmailEnabled(false)
    setCustomErrors({
      assignToField: '',
      siteField: '',
    })
    formik.setFieldValue('personId', '')
    formik.setFieldValue('notes', '')
    formik.setFieldValue('siteId', '')
    formik.setFieldValue('locationId', '')
    formik.setFieldValue('departmentId', '')
    setSelectedOption(null)
  }

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoading} />
      <PageTitle breadcrumbs={[]}>Checkout</PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0 '>
            <h3 className='fw-bolder m-0'>
              <span className='me-2'>
                <i className='la la-user-check fs-1'></i>
              </span>{' '}
              Check-Out
            </h3>
          </div>
        </div>
        <div id='kt_account_profile_details' className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-lg-9 p-md-7 p-6'>
              <div>
                {' '}
                <p className='fw-bolder m-0 mb-3'>
                  Keep track of your assets within your organization and create an even more
                  detailed history of them.
                </p>
                <div className='card-footer d-flex justify-content-start border-0 p-0'>
                  <button
                    type='button'
                    className='btn btn-light-primary me-3'
                    onClick={() => handleShowCheckoutModal()}
                  >
                    <i className='la la-plus fs-3' />
                    Select Assets
                  </button>
                  <CheckoutModal
                    show={showCheckoutModal}
                    setPendingAssets={setPendingAssets}
                    selectedAssets={selectedAssets}
                    handleClose={handleCloseCheckoutModal}
                    handleCancel={handleCancel}
                    setPage={setPage}
                    setLimit={setLimit}
                    page={page}
                    limit={limit}
                  />
                  <DuplicateReserveModal
                    show={duplicateReserveModal}
                    handleCheckoutSubmit={handleCheckoutSubmit}
                    data={reserveModalData}
                    reservedData={reservedData}
                    handleClose={handleCloseDuplicateReserveModal}
                  />
                </div>
                {selectedAssets && selectedAssets?.length > 0 ? (
                  <>
                    <div className='separator border-2 my-10'></div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4 className='mt-3'>Assets Pending Check-Out</h4>
                      {deleteDataArr.length > 0 && (
                        <button
                          type='button'
                          className='btn btn-danger  mb-2'
                          onClick={() => {
                            deleteSelectedAssests()
                          }}
                        >
                          Delete Selected
                        </button>
                      )}
                    </div>
                    <div className='checkout-listing-table mb-5 table-responsive pb-4'>
                      <table
                        id='kt_table_users'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        role='table'
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th role='columnheader' className='w-10px pe-2'>
                              <div className='me-3'>
                                <input
                                  type='checkbox'
                                  className='form-check-input custom-form-check-input'
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </div>
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Asset Tag ID
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Description
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Status
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Assigned to
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Site
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Location
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Lease to
                            </th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                          {selectedAssets?.length > 0 ? (
                            selectedAssets?.map((item: any) => {
                              return (
                                <tr role='row'>
                                  <td role='cell'>
                                    <div>
                                      <input
                                        type='checkbox'
                                        className='form-check-input custom-form-check-input'
                                        checked={selected.includes(item.id)}
                                        onChange={() => handleCheckboxChange(item.id)}
                                      />
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <div>{item?.assetTagId}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <Link to='/dummy-link'>{item?.description}</Link>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <span
                                        className={`d-inline-block align-items-center px-3 py-2 rounded ${getAssetStatusClass(item?.status)}`}
                                      >
                                        {item?.status}
                                      </span>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        {item?.assigneeType === 1 ? (
                                          <span>
                                            {`${item?.assignedPerson?.firstName || ''} ${
                                              item?.assignedPerson?.lastName || ''
                                            }`}
                                          </span>
                                        ) : item?.assigneeType === 2 ? (
                                          <span>
                                            {`${item?.assignedSite?.name || ''} ${
                                              item?.assignedSite?.name && item?.location?.location
                                                ? '/'
                                                : ''
                                            } ${item?.location?.location || ''}`}
                                          </span>
                                        ) : (
                                          <span></span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <div>{item?.site?.name}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>
                                        {item?.location?.location}
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>
                                        <span className='d-block table-elipses'>
                                          {`${item?.leased?.firstName || ''}  ${item?.leased?.lastName || ''}`}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={12}>
                                <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                  No records found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='separator border-2 my-10'></div>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                      <div className='row mb-3'>
                        <div className='col-xl-6'>
                          <div className='row mb-6 align-items-center'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                              Check-out to
                            </label>
                            <div className='col-lg-9 fv-row'>
                              <div className='d-flex'>
                                <div className='m-2 form-check-custom'>
                                  <div>
                                    <input
                                      className='form-check-input custom-form-check-input'
                                      {...formik.getFieldProps('assigneeType')}
                                      onClick={() => {
                                        setPersonCheckout(true)
                                        formik.setFieldValue('assigneeType', 1)
                                        handleAssigeeTypeChange()
                                      }}
                                      type='radio'
                                      value={1}
                                      checked={
                                        Number(formik?.values?.assigneeType) === 1 ? true : false
                                      }
                                      name={'assigneeType'}
                                      id='person'
                                    />
                                  </div>
                                  <label className='form-check-label fs-6 fw-bold' htmlFor='person'>
                                    Person
                                  </label>
                                </div>
                                <div className='m-2 form-check-custom'>
                                  <div>
                                    <input
                                      className='form-check-input custom-form-check-input'
                                      type='radio'
                                      {...formik.getFieldProps('assigneeType')}
                                      onClick={() => {
                                        setPersonCheckout(false)
                                        formik.setFieldValue('assigneeType', 2)
                                        handleAssigeeTypeChange()
                                      }}
                                      value={2}
                                      checked={
                                        Number(formik?.values?.assigneeType) === 2 ? true : false
                                      }
                                      name={'assigneeType'}
                                      id='location'
                                    />
                                  </div>
                                  <label
                                    className='form-check-label fs-6 fw-bold'
                                    htmlFor='location'
                                  >
                                    Site / Location
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                              Check-out Date
                            </label>
                            <div className='col-lg-9 fv-row'>
                              <input
                                className='form-control form-control-solid'
                                {...formik.getFieldProps('checkoutDate')}
                                type='date'
                                placeholder='Pick date rage'
                                id='kt_daterangepicker_3'
                              />
                              {formik.touched.checkoutDate && formik.errors.checkoutDate && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.checkoutDate}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={personCheckout ? '' : 'd-none'}>
                            <div className='row mb-6'>
                              <label className='col-lg-3 col-form-label fw-bold fs-6 required'>
                                <span>Assign to</span>
                              </label>
                              <div className='col-lg-9 fv-row'>
                                <div className='d-flex'>
                                  <div className='flex-1'>
                                    <Select
                                      options={assignOptions}
                                      value={selectedOption}
                                      isClearable={true}
                                      isSearchable={true}
                                      placeholder='Select a Person'
                                      classNamePrefix='custom-select'
                                      className='custom-react-select w-100'
                                      onChange={handleSelectChange}
                                    />
                                  </div>
                                  <button
                                    type='button'
                                    className='btn btn-light-primary d-flex align-items-center ms-3'
                                    onClick={() => handleShowPersonModal()}
                                  >
                                    <i className='la la-plus fs-3' />
                                    New
                                  </button>
                                </div>
                                {formik.touched.personId && formik.errors.personId && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.personId}</div>
                                  </div>
                                )}
                                {customErrors?.assignToField !== '' && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {customErrors?.assignToField}
                                    </div>
                                  </div>
                                )}
                                <PersonsModal
                                  show={showPersonModal}
                                  handleClose={handleClosePersonModal}
                                  setPersonId={setPersonId}
                                  CustomerData={[]}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label
                              className={
                                personCheckout == true
                                  ? 'col-lg-3 col-form-label fw-bold fs-6'
                                  : 'col-lg-3 col-form-label required fw-bold fs-6'
                              }
                            >
                              <span>Site</span>
                            </label>
                            <div className='col-lg-9 fv-row'>
                              <select
                                className='form-select form-control form-control-solid form-select-solid  '
                                {...formik.getFieldProps('siteId')}
                                onChange={(e) => {
                                  handleSiteClick(e)
                                  formik.handleChange(e)
                                }}
                              >
                                <option value=''>Select Site</option>
                                {userSitesList?.sites.map((item: any, idx: number) => {
                                  return (
                                    <option key={idx} value={item.id}>
                                      {item.name}
                                    </option>
                                  )
                                })}
                              </select>
                              {!personCheckout && formik.touched.siteId && formik.errors.siteId && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.siteId}</div>
                                </div>
                              )}
                              {!personCheckout && customErrors?.siteField !== '' && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{customErrors?.siteField}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                              <span>Location</span>
                            </label>
                            <div className='col-lg-9 fv-row d-flex'>
                              <select
                                className='form-select form-control form-control-solid form-select-solid  '
                                {...formik.getFieldProps('locationId')}
                                onChange={(e) => {
                                  formik.handleChange(e)
                                  const value = e.target.value
                                  if (value === '') {
                                    formik.setFieldValue('locationId', undefined)
                                  }
                                }}
                              >
                                <option value=''>Select Location</option>
                                {siteId > 0 &&
                                  userLocationList?.locations?.map((item: any, idx: number) => (
                                    <option key={idx} value={item?.id}>
                                      {item?.location}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>Due Date</label>
                            <div className='col-lg-9 fv-row'>
                              <div className='input-group'>
                                <input
                                  className='form-control form-control-solid'
                                  {...formik.getFieldProps('dueDate')}
                                  type='date'
                                  placeholder='Pick date rage'
                                  id='kt_daterangepicker_3'
                                />
                                <span className='input-group-text border-0'>
                                  <i className='fa fa-calendar'></i>
                                </span>
                              </div>
                              {formik.touched.dueDate && formik.errors.dueDate && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.dueDate}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='col-xl-6'>
                          <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                              Department
                            </label>
                            <div className='col-lg-9 fv-row'>
                              <select
                                className='form-select form-select-solid mb-5'
                                {...formik.getFieldProps('departmentId')}
                                onChange={(e) => {
                                  formik.handleChange(e)
                                  const value = e.target.value
                                  if (value === '') {
                                    formik.setFieldValue('departmentId', undefined)
                                  }
                                }}
                                aria-label='Select example'
                              >
                                <option value=''>Select Department</option>
                                {userDepartmentList?.department?.map((item: any, idx: number) => {
                                  return <option value={item?.id}>{item?.department}</option>
                                })}
                              </select>
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                              Check-out Notes
                            </label>
                            <div className='col-lg-9 fv-row'>
                              <textarea
                                className='form-control'
                                {...formik.getFieldProps('notes')}
                                cols={20}
                                rows={4}
                              ></textarea>
                              {formik.touched.notes && formik.errors.notes && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{String(formik.errors.notes)}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={personCheckout == true ? 'row mb-10' : 'd-none'}>
                            <div className='col-lg-3 col-form-label fw-bold fs-6'>
                              <div className='form-check form-check-custom  form-check-sm d-flex align-center me-5 mb-4'>
                                <input
                                  className='form-check-input me-2'
                                  type='checkbox'
                                  id='flexRadioLg'
                                  checked={emailEnabled}
                                  onChange={() => {
                                    setEmailEnabled(!emailEnabled)
                                  }}
                                />
                                <label
                                  className='form-label mb-0 fw-bold fs-6'
                                  htmlFor='flexRadioLg'
                                >
                                  Send Email
                                </label>
                              </div>
                            </div>
                            <div className='col-lg-9 fv-row'>
                              <div>
                                <input
                                  type='email'
                                  readOnly={true}
                                  value={selectedOption?.value || ''}
                                  disabled={emailEnabled == true ? false : true}
                                  className='form-control form-control-solid'
                                  placeholder='Email'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=' d-flex align-items-center justify-content-end'>
                        <button
                          onClick={handleCancel}
                          className='btn btn-light-primary   main-btn-style me-2'
                        >
                          Cancel
                        </button>
                        {personCheckout ? (
                          <button
                            type='submit'
                            className='btn btn-primary main-btn-style'
                            disabled={
                              selectedOption === null ||
                              selectedOption === '' ||
                              customErrors.assignToField !== '' ||
                              !formik.isValid
                            }
                          >
                            {!loading && 'Check-Out'}
                            {loading && (
                              <span className='indicator-progress d-block'>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        ) : (
                          <button
                            type='submit'
                            className='btn btn-primary main-btn-style'
                            disabled={
                              siteId === undefined ||
                              siteId === 0 ||
                              customErrors.siteField !== '' ||
                              !formik.isValid
                            }
                          >
                            {!loading && 'Check-Out'}
                            {loading && (
                              <span className='indicator-progress d-block'>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        )}
                      </div>
                    </form>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <Loader loading={dataLoading} />
      </div>
      <DuplicateReserveModal duplicateReserveModal={duplicateReserveModal} />
    </>
  )
}

export default Checkout
