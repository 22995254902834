import { useFormik } from 'formik'
import Modal from 'react-bootstrap/Modal'
import { initialSites } from '../Core/Model'
import * as Yup from 'yup'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../Auth'
import { useAddSiteMutation, useUpdateSiteMutation } from '../../../../Services/Sites'
import { checkUserUnauthorized } from '../../../../Auth'
import { multiSpace } from 'config'
import { countriesList } from 'Data/Countries'

const SitesSechma = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Site name is required')
    .max(20, 'Site name should not exceed 20 characters'),
  description: Yup.string()
    .trim()
    .max(250, 'Description should not exceed 250 characters')
    .nullable(),
  address: Yup.string().trim().max(50, 'Address should not exceed 50 characters').nullable(),
  suite: Yup.string().trim().max(20, 'Suite should not exceed 20 characters').nullable(),
  city: Yup.string().trim().max(20, 'City should not exceed 20 characters').nullable(),
  state: Yup.string().trim().max(20, 'State should not exceed 20 characters').nullable(),
  postalCode: Yup.string().trim().max(10, 'Postal code should not exceed 10 characters').nullable(),
})

const SitesModal = (props: any) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const [addSite, { isLoading: isAdding }] = useAddSiteMutation()
  const [updateSite, { isLoading: isUpdating }] = useUpdateSiteMutation()

  useEffect(() => {
    formik.resetForm()
    if (props?.SiteData?.id) {
      formik.setValues({
        name: props?.SiteData?.name,
        description: props?.SiteData?.description,
        address: props?.SiteData?.address,
        suite: props?.SiteData?.suite,
        city: props?.SiteData?.city,
        state: props?.SiteData?.state,
        postalCode: props?.SiteData?.postalCode,
        country: props?.SiteData?.country,
      })
    }
  }, [props])

  const formik = useFormik({
    initialValues: { ...initialSites },
    validationSchema: SitesSechma,
    onSubmit: async (values) => {
      if (currentUser) {
        values.name = values?.name?.trim().replace(multiSpace, ' ')
        try {
          const data = props?.SiteData?.id
            ? await updateSite({ siteId: props.SiteData.id, values }).unwrap()
            : await addSite({ ...values }).unwrap()
          if (data) {
            toast.dismiss()
            if (data?.success) {
              toast.success(data?.message)
              formik.resetForm()
              props.handleClose(true)
              props.setLoading(true)
            } else {
              toast.error(data?.message)
            }
          }
        } catch (error: any) {
          toast.error(error?.data?.message)
          checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
          formik.setSubmitting(false)
        }
      }
    },
  })

  const handleClose = (e: any) => {
    e?.preventDefault()
    formik.resetForm()
    props.handleClose()
  }

  const formikSubmit = (e: any) => {
    e?.preventDefault()
    formik.handleSubmit()
  }

  return (
    <Modal
      className='custom-modal-sm'
      show={props.show}
      onHide={props.handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props?.SiteData?.id ? 'Update Site' : 'Add Site'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='mb-4 fs-6'>
          Enter the data about your new site in the fields below and we will add it to your list.
        </p>
        <form noValidate className='form'>
          <div className='inner-site-modal'>
            <div className='mb-4'>
              <label className='required fw-bold fs-6 mb-2'>Site Name</label>
              <div>
                <input
                  placeholder='Site name'
                  {...formik.getFieldProps('name')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
              </div>
              {formik?.touched?.name && formik?.errors?.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik?.errors?.name}</div>
                </div>
              )}
            </div>
            <div className='mb-4'>
              <label className='fw-bold fs-6 mb-2'>Description</label>
              <div>
                <input
                  placeholder='Description'
                  {...formik.getFieldProps('description')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
                {formik?.touched?.description && formik?.errors?.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik?.errors?.description}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-4'>
              <label className=' fw-bold fs-6 mb-2'>Address</label>
              <div>
                <input
                  placeholder='Address'
                  {...formik.getFieldProps('address')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
                {formik?.touched?.address && formik?.errors?.address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik?.errors?.address}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-4'>
              <label className=' fw-bold fs-6 mb-2'>Apt./Suite</label>
              <div>
                <input
                  placeholder='Apt./Suite'
                  {...formik.getFieldProps('suite')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
                {formik?.touched?.suite && formik?.errors?.suite && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik?.errors?.suite}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-4'>
              <label className=' fw-bold fs-6 mb-2'>Country</label>

              <div className='fv-row w-100'>
                <select
                  className='form-select form-control form-control-solid form-select-solid  '
                  {...formik.getFieldProps('country')}
                >
                  <option selected> Select country</option>
                  {countriesList?.map((item: any, idx: number) => {
                    return (
                      <option key={idx} value={item.code}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='mb-4'>
              <label className=' fw-bold fs-6 mb-2'>City</label>
              <div>
                <input
                  placeholder='City'
                  {...formik.getFieldProps('city')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
                {formik?.touched?.city && formik?.errors?.city && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik?.errors?.city}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-4'>
              <label className=' fw-bold fs-6 mb-2'>State</label>
              <div>
                <input
                  placeholder='State'
                  {...formik.getFieldProps('state')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
                {formik?.touched?.state && formik?.errors?.state && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik?.errors?.state}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-4'>
              <label className=' fw-bold fs-6 mb-2'>Postal Code</label>
              <div>
                <input
                  placeholder='Postal Code'
                  {...formik.getFieldProps('postalCode')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
                {formik?.touched?.postalCode && formik?.errors?.postalCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik?.errors?.postalCode}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='text-end pt-2'>
            <button
              className='btn btn-light-primary   main-btn-style me-2'
              onClick={(e) => handleClose(e)}
            >
              Close{' '}
            </button>
            <button
              type='submit'
              className='btn btn-primary main-btn-style'
              onClick={(e) => formikSubmit(e)}
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            >
              {!isAdding && !isUpdating && (
                <span className='indicator-label'>
                  {props?.SiteData?.id ? 'Update Site' : 'Add Site'}
                </span>
              )}
              {(isAdding || isUpdating) && (
                <span className='indicator-progress d-block'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default SitesModal
