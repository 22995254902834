import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../Auth'
import { AssetsHeader } from './AssetsHeader'
import { useDeleteAssetsMutation, useGetAllAssetsQuery } from '../../../../Services/Assets'
import { Link } from 'react-router-dom'
import { checkUserUnauthorized } from '../../../../Auth'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import { nameKeys, setupKeys } from 'config'
import { ColumnData } from './RenderColumnData'
import { assetSearchFields } from '../Core/Model'
import ConfirmationPopup from '../../../../../../Template/helpers/components/ConfirmationPopup'
import AssetsSearch from './AssetsSearch'
import Pagination from 'rc-pagination'
import moment from 'moment'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import getSymbolFromCurrency from 'currency-symbol-map'
import AssetsTagList from './AssetsTagList'
import SplashScreen from 'App/Loaders/SplashScreen'
import SetupColumns from './SetupColumns'
import SetupCoulmnListing from './SetupColumnListing'
import Loader from 'App/Loaders/BeatLoader'
import AssetColumns from '../../../../../../Data/AssetColumns.json'
import 'rc-pagination/assets/index.css'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import ScanModal from './Modals/ScanModal'

const AssetsListing = () => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const staffUser = currentUser?.userType === 2
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4
  const [deleteDataArr, setDeleteDataArr] = useState<any>([])
  const [selectAllData, setSelectAllData] = useState<boolean>(false)
  const [selectedData, setSelectedData] = useState<boolean>(false)
  const [assets, setAssets] = useState<any>([])
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [check, setCheck] = useState<number[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [skip, setSkip] = useState<boolean>(false)
  const [selected, setSelected] = useState<any>([])
  const [currencyLogo, setCurrencyLogo] = useState<any>()
  const [selectAll, setSelectAll] = useState<any>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [exportButton, setExportButton] = useState<boolean>(false)
  const [pagination, setPagination] = useState<any>({})
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [criteria, setCriteria] = useState<any>([])
  const [showSetupColumns, setShowSetupColumns] = useState<boolean>(false)
  const savedSearchAsset = JSON.parse(sessionStorage.getItem('searchAssets') || '{}')
  const [selectedColumns, setSelectedColumns] = useState<{ key: any; value: any }[]>(AssetColumns)
  const [toastRendered, setToastRendered] = useState(false)
  const [scanModal, setQrModal] = useState<boolean>(false)
  const toggleScanModal = (): void => setQrModal(!scanModal)
  const [assetID, setAssetID] = useState<any>(true)
  const [searchAssets, setSearchAssets] = useState<any>({
    ...assetSearchFields,
    exportToExcel: false,
  })
  const [resetAssetsSearchStates, setResetAssetsSearchStates] = useState(false)
  const selectedColumnsRender = selectedColumns.filter((column) => column.value === true)

  const {
    data: companyData,
    isSuccess: company,
    isLoading: isLoadingGetCompany,
    refetch: refetchComapnyData,
  } = useGetCompanyQuery(
    {},
    {
      skip: personUser || customerUser || (staffUser && !permission?.viewCompany),
    }
  )

  const excelKeys = selectedColumnsRender.map((column) => column.key.replace(/_/g, ' '))

  const {
    data: assetsData,
    isLoading: isLoadingGetAssets,
    error,
    isSuccess,
    isError,
  } = useGetAllAssetsQuery(
    {
      body: new URLSearchParams(searchAssets).toString(),
      page,
      limit,
      keys: excelKeys,
    },
    { skip }
  )

  const [deleteAssets, { isLoading: isLoadingDelete }] = useDeleteAssetsMutation()

  useEffect(() => {
    setSearchAssets(savedSearchAsset)
  }, [])

  useEffect(() => {
    setLoading(false)

    switch (true) {
      case isSuccess:
        setExportButton(false)
        if (!toastRendered) {
          toast.dismiss()
          toast.success(
            `Assets ${searchAssets?.exportToExcel ? 'exported' : 'fetched'} successfully`
          )
          setToastRendered(true)
        }
        break

      case isError:
        if (error) {
          const err: any = error
          if (!toastRendered) {
            toast.dismiss()
            toast.error(err?.data?.message)
            setToastRendered(true)
            checkUserUnauthorized(err?.data, saveAuth, setCurrentUser, toast)
          }
        }
        break

      default:
        break
    }

    return () => {
      setToastRendered(false)
    }
  }, [assetsData, error, isError, isSuccess])

  useEffect(() => {
    const res = getSymbolFromCurrency(companyData?.company?.currency || currentUser?.currency)
    setCurrencyLogo(res)
  }, [company])

  useEffect(() => {
    if (isError) {
      const errorData = error as { data: any }
      checkUserUnauthorized(errorData?.data, saveAuth, setCurrentUser, toast)
    }
  }, [isError])

  const handleSearchAssets = (e) => {
    if (!e) return
    const { name, value, update, target } = e
    switch (name) {
      case nameKeys.searchField: {
        const valuesArray = value
          .map((obj) => (obj.value !== '*' ? obj.value : null))
          .filter(Boolean)
        const valuesArrayLabel = value.map((obj) => ` ${obj.label}`)
        setSearchAssets((prevSearchAssets: any) => ({
          ...prevSearchAssets,
          searchField: valuesArray.toString(),
        }))
        setCriteria((prevCriteria: any) => ({
          ...prevCriteria,
          searchField: valuesArrayLabel.toString(),
        }))
        break
      }

      case nameKeys.status: {
        const valuesArray = value.map((obj) => obj.value)
        const valuesArrayLabel = value.map((obj) => ` ${obj.label}`)
        setSearchAssets((prevSearchAssets: any) => ({
          ...prevSearchAssets,
          status: valuesArray.toString(),
        }))
        setCriteria((prevCriteria: any) => ({
          ...prevCriteria,
          status: valuesArrayLabel.toString(),
        }))
        break
      }

      case nameKeys.customDate: {
        const { dateRangeBy } = e
        const startDate = update[0] ? moment(update[0]).format('YYYY-MM-DD') : ''
        const endDate = update[1] ? moment(update[1]).format('YYYY-MM-DD') : ''
        setSearchAssets((prevSearchAssets: any) => ({
          ...prevSearchAssets,
          startDate,
          endDate,
          dateRangeBy: dateRangeBy === 'dateCreated' ? 'createdAt' : dateRangeBy,
        }))
        setCriteria((prevCriteria: any) => ({
          ...prevCriteria,
          startDate,
          endDate,
        }))
        break
      }

      case nameKeys.quickDate: {
        const { dateRangeBy } = e
        const label = dateRangeBy
        const startDate = update[0] ? moment(update[0]).format('YYYY-MM-DD') : ''
        const endDate = update[1] ? moment(update[1]).format('YYYY-MM-DD') : ''
        setSearchAssets((prevSearchAssets: any) => ({
          ...prevSearchAssets,
          startDate,
          endDate,
          dateRangeBy: dateRangeBy === 'dateCreated' ? 'createdAt' : dateRangeBy,
        }))
        const { selectedOptions } = target
        setCriteria((prevCriteria: any) => ({
          ...prevCriteria,
          [label]: selectedOptions ? selectedOptions[0]?.label : value,
        }))
        break
      }

      case nameKeys.recordCount: {
        const { value: recordCount } = target
        setLimit(recordCount)
        setCriteria((prevCriteria: any) => ({
          ...prevCriteria,
          recordCount,
        }))
        break
      }

      case nameKeys.restricted: {
        const restrictedValue = value === 'true' ? 'true' : 'false'
        setSearchAssets((prevSearchAssets: any) => ({
          ...prevSearchAssets,
          restricted: restrictedValue,
        }))
        setCriteria((prevCriteria: any) => ({
          ...prevCriteria,
          restricted: restrictedValue === 'true' ? 'Restricted' : 'Not Restricted',
        }))
        break
      }

      default: {
        const { name: targetName, value: targetValue, selectedOptions } = e.target || {}
        setSearchAssets((prevSearchAssets: any) => ({
          ...prevSearchAssets,
          [targetName]: targetValue == 0 ? '' : targetValue,
        }))
        setCriteria((prevCriteria: any) => ({
          ...prevCriteria,
          [targetName]: selectedOptions ? selectedOptions[0]?.label : targetValue,
        }))
        break
      }
    }
  }

  const clearSearch = (keyToRemove) => {
    const savedCriteria = JSON.parse(sessionStorage.getItem('criteria') || '{}')
    const searchAsset = JSON.parse(sessionStorage.getItem('searchAssets') || '{}')
    const updatedCriteria = { ...savedCriteria }
    const updatedSearchAssets = { ...searchAsset }
    switch (keyToRemove) {
      case 'keyword':
      case 'searchField':
        delete updatedCriteria['keyword']
        delete updatedSearchAssets['keyword']
        delete updatedCriteria['searchField']
        delete updatedSearchAssets['searchField']
        break

      case 'purchasedDate':
      case 'dateCreated':
        delete updatedCriteria[keyToRemove]
        delete updatedSearchAssets['dateRangeBy']
        delete updatedSearchAssets['startDate']
        delete updatedSearchAssets['endDate']
        break

      default:
        delete updatedCriteria[keyToRemove]
        delete updatedSearchAssets[keyToRemove]
        break
    }

    setCriteria(updatedCriteria)
    sessionStorage.setItem('criteria', JSON.stringify(updatedCriteria))
    setSearchAssets(updatedSearchAssets)
    sessionStorage.setItem('searchAssets', JSON.stringify(updatedSearchAssets))
    handleSearchSubmit()
    setLoading(false)
  }

  const handleSearchSubmit = () => {
    setPage(1)
    setSkip(false)
    setShowSearch(false)
    setLoading(true)
  }

  useEffect(() => {
    if (isSuccess) {
      setSkip(true)
    }
  }, [showSearch])

  useEffect(() => {
    setSkip(false)
  }, [])

  useEffect(() => {
    if (currentUser && assetsData?.assets) {
      setAssets(assetsData?.assets)
      setPagination(assetsData?.pagination)
      setSkip(true)
      setLoading(false)
    }
  }, [assetsData])

  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      const updatedSelected = prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
      const isAllSelected = updatedSelected.length === assetsData?.assets?.length
      setSelectAll(isAllSelected)
      return updatedSelected
    })
    setDeleteDataArr((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assetsData?.assets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setDeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setDeleteDataArr([])
    }
  }

  useEffect(() => {
    if (selectAllData) {
      const newSelecteds = assetsData?.assets?.map((item: any) => item.id) || []
      setSelected(newSelecteds)
      setDeleteDataArr(newSelecteds)
    }
    setSelectAllData(false)
  }, [selectedData])

  useEffect(() => {
    if (deleteDataArr && deleteDataArr.length === 0) {
      const multicheckSelectCheckboxes = document.querySelectorAll(nameKeys.multiCheckSelectName)
      multicheckSelectCheckboxes.forEach((checkbox) => {
        if (checkbox instanceof HTMLInputElement) {
          checkbox.checked = false
        }
      })
    } else {
      let multicheckSelectCheckboxesTrue
      let multicheckSelectCheckboxFalse
      switch (selectAll) {
        case true:
          multicheckSelectCheckboxesTrue = document.querySelectorAll(nameKeys.multiCheckSelectName)
          multicheckSelectCheckboxesTrue.forEach((checkbox) => {
            if (checkbox instanceof HTMLInputElement) {
              checkbox.checked = true
            }
          })
          break

        case false:
          multicheckSelectCheckboxFalse = document.querySelector(
            nameKeys.multiCheckSelectName
          ) as HTMLInputElement
          if (multicheckSelectCheckboxFalse) {
            multicheckSelectCheckboxFalse.checked = false
          }
          break

        default:
          break
      }
    }
  }, [deleteDataArr])

  const deleteSelectedAssets = async (id: number[]) => {
    try {
      const deleteIds = { deleteIds: id }
      const res = await deleteAssets({ id: deleteIds }).unwrap()
      toast.dismiss()
      toast.success(res?.message)
      setSkip(false)
    } catch (err: any) {
      setLoading(false)
      toast.error(err?.data?.message)
      checkUserUnauthorized(err?.data, saveAuth, setCurrentUser, toast)
    }
    setCheck([])
    setDeleteDataArr([])
    setSelectAll(false)
    setDeleteModal(false)
    setSelected([])
    setLoading(true)
  }

  const cancel = () => setDeleteModal(false)

  const confirm = () => {
    cancel()
    deleteSelectedAssets(check)
  }

  const handlePageChange = (currentPage: any) => {
    setLoading(true)
    setPage(currentPage)
    setSkip(false)
  }

  const handleLimitChange = (newLimit: number) => {
    setLoading(true)
    setLimit(Number(newLimit))
    setPage(1)
    setSkip(false)
  }

  const handleExport = () => {
    setExportButton(true)
    setSearchAssets((prevSearchAssets) => ({
      ...prevSearchAssets,
      exportToExcel: true,
    }))
    handleSearchSubmit()
  }

  useEffect(() => {
    if (assetsData?.fileName && searchAssets?.exportToExcel) {
      downloadAndRefresh()
    }
  }, [assetsData, exportButton])

  const downloadAndRefresh = () => {
    const link = document.createElement('a')
    link.href = process.env.REACT_APP_PUBLIC_URL + assetsData?.fileName
    link.target = `_blank`
    link.download = `downloaded-file-name`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setSearchAssets((prevSearchAssets) => ({
      ...prevSearchAssets,
      exportToExcel: false,
    }))
  }

  const handleQrCodeView = (id) => {
    setAssetID(id)
    toggleScanModal()
  }

  const currentPage = pagination?.page || 0
  const totalRecords = pagination?.total || 0
  const pageSize = limit || 10
  const startRecord = (currentPage - 1) * pageSize + 1
  const endRecord = Math.min(currentPage * pageSize, totalRecords)

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoadingGetAssets || isLoadingDelete} />
      {!(isLoadingGetAssets || isLoadingDelete) ? (
        <>
          <AssetsSearch
            setSearchAssets={setSearchAssets}
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            searchAssets={searchAssets}
            handleSearchAssets={handleSearchAssets}
            handleSearchSubmit={handleSearchSubmit}
            setResetAssetsSearchStates={setResetAssetsSearchStates}
            resetAssetsSearchStates={resetAssetsSearchStates}
            setLoading={setLoading}
            setCriteria={setCriteria}
            permission={permission}
            personUser={personUser}
            customerUser={customerUser}
            staffUser={staffUser}
          />
          <SetupCoulmnListing
            showSetupColumns={showSetupColumns}
            setShowSetupColumns={setShowSetupColumns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            assetsData={assetsData}
          />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <div
                id='kt_content_container'
                className={`container ${(showSearch || showSetupColumns) && 'd-none'}`}
              >
                <div className='card'>
                  <div className='d-inline-block mx-2'></div>
                  <div className='card-body'>
                    <ConfirmationPopup
                      deleteModal={deleteModal}
                      cancel={cancel}
                      confirm={confirm}
                      setSkip={setSkip}
                      setupName={setupKeys.asset}
                    />
                    <AssetsHeader
                      assetsData={assetsData}
                      userAssets={assetsData?.assets}
                      fileName={assetsData?.fileName}
                      deleteDataArr={deleteDataArr}
                      setDeleteModal={setDeleteModal}
                      setCheck={setCheck}
                      setShowSearch={setShowSearch}
                      setShowSetupColumns={setShowSetupColumns}
                      handleExport={handleExport}
                      setResetAssetsSearchStates={setResetAssetsSearchStates}
                    />
                    <div className='mb-5'>
                      <AssetsTagList
                        clearSearch={clearSearch}
                        criteria={criteria}
                        searchAssets={searchAssets}
                      />
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap mb-4'>
                      <div className='d-flex limit-options align-items-center mb-sm-0 mb-3'>
                        <span className='text-muted'>Showing</span>
                        <select
                          className='form-lect form-select-sm fw-bold mx-3 border-1'
                          onChange={(e) => handleLimitChange(Number(e.target.value))}
                          value={limit}
                        >
                          <option value='10'>10</option>
                          <option value='15'>15</option>
                          <option value='20'>20</option>
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                        </select>
                        <span className='text-muted'>entries</span>
                      </div>
                      <SetupColumns setShowSetupColumns={setShowSetupColumns} />
                    </div>
                    <div className='react-grid-card'>
                      <div className='table-responsive pb-4'>
                        <table
                          id='kt_table_users'
                          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        >
                          <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                              {!permission?.deleteAsset && staffUser ? (
                                ''
                              ) : (
                                <th role='columnheader' className='w-10px pe-2'>
                                  <div className='header-checkbox me-3'>
                                    <input
                                      type='checkbox'
                                      className='form-check-input custom-form-check-input'
                                      checked={selectAll}
                                      onChange={handleSelectAll}
                                    />
                                  </div>
                                </th>
                              )}
                              {selectedColumnsRender.map((column, index) => (
                                <th key={index}>{column.key.replace(/_/g, ' ')}</th>
                              ))}
                              <th className='text-center'>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {assets?.length > 0 ? (
                              assets.map((asset: any, rowIndex: number) => (
                                <tr key={rowIndex} role='row'>
                                  {!permission?.deleteAsset && staffUser ? (
                                    ''
                                  ) : (
                                    <td role='cell'>
                                      <div className='form-check form-check-sm form-check-custom'>
                                        <input
                                          type='checkbox'
                                          className='form-check-input'
                                          checked={selected.includes(asset?.id)}
                                          onChange={() => handleCheckboxChange(asset?.id)}
                                        />
                                      </div>
                                    </td>
                                  )}
                                  {selectedColumnsRender.map((column, colIndex) => (
                                    <td key={colIndex} className='table-elipses'>
                                      <ColumnData
                                        column={column.key.replace(/_/g, ' ')}
                                        assetsData={{ assets: [asset] }}
                                        currencyLogo={currencyLogo}
                                      />
                                    </td>
                                  ))}
                                  <td className='border-0'>
                                    <Link
                                      to={`/asset/view/${asset.id}`}
                                      className='menu-link px-5 btn btn-light-secondary p-2 view-btn-style'
                                    >
                                      View
                                    </Link>
                                    <span
                                      onClick={() => handleQrCodeView(asset?.id)}
                                      className='menu-link px-5 btn btn-light-secondary p-2 view-btn-style ms-3'
                                    >
                                      Scan Asset
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={selectedColumnsRender?.length + 2}
                                  className='text-center'
                                >
                                  <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                    No records found
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {scanModal && (
                      <ScanModal
                        scanModal={scanModal}
                        handleClose={toggleScanModal}
                        assetId={assetID}
                      />
                    )}
                    {assetsData?.assets?.length ? (
                      <div className='d-sm-flex align-items-center justify-content-between flex-wrap mt-3'>
                        <div className='m-1'>
                          Showing {startRecord} to {endRecord} of {totalRecords} records
                        </div>
                        {pagination && (
                          <Pagination
                            className='m-1 custom-pagination'
                            defaultCurrent={1}
                            pageSize={limit}
                            current={pagination?.page}
                            total={pagination?.total}
                            onChange={handlePageChange}
                            locale={localeInfo}
                          />
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <Loader loading={loading} />
    </>
  )
}

export default AssetsListing
