import { toAbsoluteUrl } from 'Template/helpers'
import { Images } from 'Template/assets/Assets'
import { Link } from 'react-router-dom'
import './Footer.css'

function Footer() {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  return (
    <footer className='footer-section d-flex justify-content-center align-items-center flex-column position-relative z-2'>
      <div className='custom-container container'>
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <Link to={'/'}>
            <div className='image mb-lg-5 mb-4'>
              <img
                loading='lazy'
                src={toAbsoluteUrl(Images.DefaultWhiteLogo)}
                alt='Logo'
                className='h-25px h-xl-30px img-fluid'
              />
            </div>
          </Link>
          <p className=' mb-lg-5 mb-4'>&copy; {currentYear} All Rights Reserved Asset Rack</p>
          <ul className='social-icons list-unstyled d-flex'>
            <li>
              <Link to={'https://www.facebook.com/login/'}>
                <i className='lab la-facebook-f'></i>
              </Link>
            </li>
            <li>
              <Link to={'https://x.com/asset_rack'}>
                <i className='lab la-twitter'></i>
              </Link>
            </li>
            <li>
              <Link to={'https://www.linkedin.com/company/asset-rack'}>
                <i className='lab la-linkedin-in'></i>
              </Link>
            </li>
            <li>
              <Link to={'https://www.instagram.com/assetrack3/'}>
                <i className='fa-brands fa-instagram'></i>
              </Link>
            </li>
            {/* <li>
              <Link to={'https://www.pinterest.com/login/'}>
                <i className='lab la-pinterest-p'></i>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
